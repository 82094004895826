import { createSlice } from '@reduxjs/toolkit';

interface initialStateTypes {
  isAuthorized: boolean;
  loading: boolean;
  hasErrors: boolean;
  data: [];
  pageCount: Number;
  pagination: {};
  sorting: {};
  keyword: String;
  startDate: String;
  endDate: String;
  totalUser:Number;
  userCurrentPage:Number;
}

export interface finalStateTypes {
  app: {
    isAuthorized: boolean;
    loading: boolean;
    hasErrors: boolean;
    data: [];
    pageCount: Number;
    keyword: String;
    startDate: String;
    endDate: String;
    pagination: {};
    sorting: {};
    totalUser:Number;
    userCurrentPage:Number;
  };
}

const initialState: initialStateTypes = {
  isAuthorized: false,
  loading: false,
  hasErrors: false,
  data: [],
  pageCount: 0,
  pagination: {},
  sorting: {},
  keyword: '',
  startDate: '',
  endDate: '',
  totalUser:0,
  userCurrentPage:1
};

const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
    },
    requestCompleted: (state) => {
      state.loading = false;
    },

    requestSuccess(state, { payload }) {
      state.data = payload;
      state.hasErrors = false;
      state.loading = false;
    },

    requestFailure(state) {
      state.loading = false;
      state.hasErrors = true;
    },

    resetData(state) {
      state.data = [];
    },

    resetPageCount(state, { payload }) {
      state.pageCount = payload;
    },

    resetTotalUser(state, { payload }) {
      state.totalUser = payload;
    },

    resetUserCurrentPage(state, { payload }) {
      state.userCurrentPage = payload;
    },
    resetSearchData(state, { payload }) {
      if (payload?.keyword) {
        state.keyword = payload?.keyword;
      }
      if (payload?.startDate) {

        state.startDate = payload?.startDate;
      }
      if (payload?.endDate) {
        state.endDate = payload?.endDate;
      }
    },

    setPagination(state, { payload }) {
      state.pagination = payload;
    },
    setSorting(state, { payload }) {
      state.sorting = payload;
    },
  },
});

export const data = (state: finalStateTypes) => state.app.data;
export const pagination = (state: finalStateTypes) => state.app.pagination;
export const sorting = (state: finalStateTypes) => state.app.sorting;
export const count = (state: finalStateTypes) => state.app.pageCount;
export const loading = (state: finalStateTypes) => state.app.loading;
export const keyword = (state: finalStateTypes) => state.app.keyword;
export const startDate = (state: finalStateTypes) => state.app.startDate;
export const endDate = (state: finalStateTypes) => state.app.endDate;
export const totalUser = (state: finalStateTypes) => state.app.totalUser;
export const userCurrentPage = (state: finalStateTypes) => state.app.userCurrentPage;

export const {
  requestStart,
  requestSuccess,
  resetUserCurrentPage,
  requestFailure,
  requestCompleted,
  resetData,
  resetPageCount,
  resetSearchData,
  setPagination,
  resetTotalUser,
  setSorting,
} = appReducer.actions;

export default appReducer.reducer;
