import { AppRoutes } from 'src/Components/Constant/routes';
import { HomeWrapper } from './styles';
import CustomTabs from 'src/Components/Shared/Tabmenu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { JobsAction } from 'src/Redux/App/Actions/Jobs';
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const record = location.state && location.state;
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate(AppRoutes.home);
      if (localStorage.getItem('key') === '1') {
         dispatch<any>(JobsAction(1, 25, '', '', ''));
      }
    } else {
      navigate(AppRoutes.login);
    }
    // Empty dependency array to run only once
  }, []); 
  return (
    <>
      {localStorage.getItem('token') ? (
        <HomeWrapper>
          <CustomTabs />
        </HomeWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default Home;
