import { Button, Col, Form, Input, Row } from 'antd';
import SearchIcon from 'src/Assets/Icons/SearchIcon';
import { SearchFormWraper, TableWraper } from 'src/Styles/global';
import { columns } from 'src/Assets/Content/JobOrders/data';
import { Pagination, Table } from 'antd';
import { data, loading, count } from '../../Redux/App/index';
import { useEffect, useState } from 'react';
import Previous from 'src/Assets/Icons/Previous';
import Next from 'src/Assets/Icons/Next';
import { useDispatch } from 'react-redux';
import {
  JobsAction,
  SearchJobAction,
  sendInvoice,
} from 'src/Redux/App/Actions/Jobs';
import { useSelector } from 'react-redux';
interface DataType {
  [key: string]: any;
}

const JobOrders = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector(loading);
  useEffect(() => {
    // This runs only once when the component mounts
    dispatch<any>(JobsAction(1, 25, '', '', ''));

    // Cleanup function
    return () => {
      console.log('Cleaning up...');
    };
  }, []);
  const [keyword, setKeyword] = useState<string>('');
  const submittedJobs = useSelector(data);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25); // Default page size

  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-White' : 'table-row-lite';
  };
  const handleSizeChange = (current: number, size: number) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const total: any = useSelector(count);
  const totalItems = total ? total : 0;
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = submittedJobs;
  let dynamicPageSizeOptions = ['25', '50', '100'];
  const SearchSubmittedJobs = () => {
    let page = 1;
    let pageSize = 25;
    setCurrentPage(page);
    setPageSize(pageSize)
    dispatch<any>(
      SearchJobAction({
        keyword,
        startDate: '',
        endDate: '',
        sortBy: '',
        sortOrder: '',
        page,
        pageSize,
      })
    );
  };
  return (
    <>
      <SearchFormWraper>
        <Form layout='horizontal'>
          <Row
            style={{ marginBottom: '12px' }}
            justify={'end'}
          >
            <Col
              md={7}
              style={{ display: 'flex' }}
            >
              <Input
                type='search'
                placeholder='Search...'
                prefix={<SearchIcon />}
                style={{ marginRight: 8 }}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Button
                onClick={() => SearchSubmittedJobs()}
                className='search-butn'
                htmlType='submit'
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </SearchFormWraper>
      <TableWraper>
        <Table
          style={{ height: '70vh', overflow: 'auto' }}
          dataSource={currentItems}
          columns={columns}
          rowClassName={getRowClassName}
          pagination={false}
          loading={loadingState}
        />
        <div>
          {totalItems > 25 ? (
            <Pagination
              pageSize={pageSize}
              total={totalItems}
              current={currentPage}
              showSizeChanger={true}
              showTotal={(total) => `Result ${pageSize} out of ${total}`}
              onChange={(page: number, pageSize?: number) => {
                setCurrentPage(page);
                if (pageSize) {
                  setPageSize(pageSize);


                  dispatch<any>(SearchJobAction({
                    keyword,
                    startDate: '',
                    endDate: '',
                    sortBy: '',
                    sortOrder: '',
                    page,
                    pageSize,
                  }));
                }
              }}
              onShowSizeChange={handleSizeChange}
              prevIcon={<Previous disabled={currentPage === 1} />}
              nextIcon={<Next disabled={indexOfLastItem >= totalItems} />}
              pageSizeOptions={dynamicPageSizeOptions}
              itemRender={(page, type, originalElement) => {
                if (type === 'page') {
                  return <span>{page}</span>;
                }
                return originalElement;
              }}
            />
          ) : (
            ''
          )}
        </div>
      </TableWraper>
    </>
  );
};

export default JobOrders;
