import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import SearchIcon from 'src/Assets/Icons/SearchIcon';
import { SearchFormWraper, TableWraper } from 'src/Styles/global';
import { useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { columns } from 'src/Assets/Content/JobApplications/data';
import Previous from 'src/Assets/Icons/Previous';
import Next from 'src/Assets/Icons/Next';
import { useSelector } from 'react-redux';
import { data, loading, count,  keyword,endDate,startDate } from '../../Redux/App/index';
import { useDispatch } from 'react-redux';
import { JobsAction, SearchJobAction } from 'src/Redux/App/Actions/Jobs';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/Components/Constant/routes';
const JobApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingState = useSelector(loading);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25); // Default page size
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [sortBy, setKey] = useState<string>('');
  const [sortOrder, setOrder] = useState<string>('');
  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-White' : 'table-row-lite';
  };
  const handleSizeChange = (current: number, size: number) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const submittedJobs = useSelector(data);
  const total: any = useSelector(count);


  const totalItems = total ? total : 0;
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = submittedJobs;
  let dynamicPageSizeOptions = ['25', '50', '100'];
  const SearchSubmittedJobs = () => {
    let page = 1;
    let pageSize = 25;
    setCurrentPage(page);
    setPageSize(pageSize)
    dispatch<any>(
      SearchJobAction({
        keyword,
        startDate,
        endDate,
        sortBy,
        sortOrder,
        page,
        pageSize,
      })
    );
  };
  const handleRowClick = (record: any) => {
    navigate(AppRoutes.application, { state: { record } });
  };

  const handleColumnHeaderClick = (key: any, order: any) => {
    setKey(key);
    setOrder(order);
    let sortBy = key;
    let sortOrder = order;
    let page = 1;
    let pageSize = 25;
    dispatch<any>(
      SearchJobAction({
        keyword,
        startDate,
        endDate,
        sortBy,
        sortOrder,
        page,
        pageSize,
      })
    );
  };

  useEffect(() => {}, [total]);
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order == undefined) {
      handleColumnHeaderClick('submittedDate', 'DESC'); // Pass the column key and sort order to your click handler function
    } else {
      if (sorter && sorter.columnKey) {
        handleColumnHeaderClick(sorter.columnKey, sorter.order); // Pass the column key and sort order to your click handler function
      }
    }
  };

  return (
    <>
      <SearchFormWraper>
        <Form layout='horizontal'>
          <Row style={{ marginBottom: '12px' }}>
            <Col md={7}>
              <Form.Item
                label='Start Date'
                name='start_date'
                rules={[
                  {
                    required: false,
                    message: ' Please input start date',
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: '248px',
                    background: 'rgba(242, 241, 240, 0.3)',
                    padding: '8px 12px',
                  }}
                  allowClear={false}
                  format='YYYY-MM-DD'
                  placeholder={''}
                  suffixIcon={null}
                  onChange={(date, dateString) =>
                    setStartDate(String(dateString))
                  }
                />
              </Form.Item>
            </Col>
            <Col
              md={10}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Form.Item
                label='End Date'
                name='end_date'
                rules={[
                  {
                    required: false,
                    message: ' Please input end date',
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: '248px',
                    background: 'rgba(242, 241, 240, 0.3)',
                    padding: '8px 12px',
                  }}
                  allowClear={false}
                  format='YYYY-MM-DD'
                  placeholder={''}
                  suffixIcon={null}
                  onChange={(date, dateString) =>
                    setEndDate(String(dateString))
                  }
                />
              </Form.Item>
            </Col>
            <Col
              md={7}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <Input
                type='search'
                placeholder='Search...'
                prefix={<SearchIcon />}
                style={{ marginRight: 8 }}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trimStart(); // Remove leading spaces
                  setKeyword(trimmedValue)
                }}
              />
              <Button
                onClick={() => SearchSubmittedJobs()}
                className='search-butn'
                htmlType='submit'
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </SearchFormWraper>
      <TableWraper>
        <Table
          style={{ height: '70vh', overflow: 'auto' }}
          dataSource={currentItems}
          columns={columns}
          rowClassName={getRowClassName}
          pagination={false}
          loading={loadingState}
          onRow={(record, rowIndex) => ({
            onClick: (event) => {
              const target = event.target as HTMLElement;
              if (target.tagName === 'A') {
                localStorage.setItem('pakageId', record.packagesId);
                handleRowClick(record);
              }
            },
          })}
          onChange={handleTableChange}
        />
        <div>
          {totalItems > 20 ? (
            <Pagination
              pageSize={pageSize}
              total={totalItems}
              current={currentPage}
              showSizeChanger={true}
              showTotal={(total) => `Result ${pageSize} out of ${total}`}
              onChange={(page: number, pageSize?: number) => {
                setCurrentPage(page);
                if (pageSize) {
                  setPageSize(pageSize);     
                  dispatch<any>(
                    SearchJobAction({
                      keyword,
                      startDate,
                      endDate,
                      sortBy,
                      sortOrder,
                      page,
                      pageSize,
                    })
                  );
                }
              }}
              onShowSizeChange={handleSizeChange}
              prevIcon={<Previous disabled={currentPage === 1} />}
              nextIcon={<Next disabled={indexOfLastItem >= totalItems} />}
              pageSizeOptions={dynamicPageSizeOptions}
              itemRender={(page, type, originalElement) => {
                if (type === 'page') {
                  return <span>{page}</span>;
                }
                return originalElement;
              }}
            />
          ) : (
            ''
          )}
        </div>
      </TableWraper>
    </>
  );
};

export default JobApplication;
