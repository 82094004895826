import React, { useState } from 'react';
import SubHeading from 'src/Components/Shared/SubHeading';
import { FormWraper } from 'src/Styles/global';
import { Button, Col, Form, Image, Input, Row } from 'antd';
import print from './../../../Assets/Icons/print-icon.svg';
import NotificationModal from 'src/Components/Pages/Auth/NotificationModal';
import WarningIcon from '../../../Assets/Icons/WarningIcon';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorIcon from 'src/Assets/Icons/ErrorIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoutes } from 'src/Components/Constant/routes';
import { ResetPasswordAction } from 'src/Redux/App/Actions/Auth';
import { loading as stateLoading } from 'src/Redux/App';
import SuccessIcon from 'src/Assets/Icons/SuccessIcon';

const ResetPassword: React.FC = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [content, setContent] = useState('Please fill in both Password fields');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(stateLoading);

  const { token } = useParams();

  const closeModal = () => {
    setModalVisible(false);
  };
  const onFinishFailed = () => {
    setModalVisible(true);
    setContent('Please fill in both Password fields');
  };
  const onFinish = (values: any) => {
    const { users_password, confirm } = values;
    if (users_password !== confirm) {
      setModalVisible(true);
      setContent("Your password entries don't match. /n Please try again.");
    } else {
      dispatch<any>(
        ResetPasswordAction(values, token as string, navigate, successfulReset)
      );
    }
  };
  const successfulReset = () => {
    setSuccessModalVisible(true);
    setContent('Password updated successfully');
    setTimeout(() => {
      navigate(AppRoutes.login);
    }, 2000);
  };

  return (
    <>
      <Row justify='space-between'>
        <Col
          xs={20}
          sm={20}
          md={20}
          lg={18}
          xl={10}
          xxl={10}
        >
          <>
            <SubHeading>Password Reset</SubHeading>
            {/* Reset Password */}
            <FormWraper>
              <Form
                layout='vertical'
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
              >
                <Form.Item
                  label='New Password'
                  name='users_password'
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className='error-icon'>
                          <ErrorIcon />
                          Please input your password
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input type={'password'} />
                </Form.Item>
                <Form.Item
                  label='Re-enter your New Password'
                  name='confirm'
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className='error-icon'>
                          <ErrorIcon />
                          Please re-enter your new password
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input type={'password'} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                  >
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            </FormWraper>
            <NotificationModal
              visible={isModalVisible}
              onClose={closeModal}
              content={content}
              icon={<WarningIcon />}
            />
            <NotificationModal
              visible={isSuccessModalVisible}
              onClose={closeModal}
              content={content}
              icon={<SuccessIcon />}
            />
          </>
        </Col>
        <Col
          xs={2}
          sm={2}
          md={4}
          lg={2}
          xl={2}
          xxl={2}
          style={{ textAlign: 'end' }}
        >
          <Image
            src={print}
            preview={false}
            className='print-icon'
            onClick={() => window.print()}
          />
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
