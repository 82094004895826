import StatusFailed from 'src/Assets/Icons/StatusFailed';
import StatusSuccess from 'src/Assets/Icons/StatusSuccess';
import StatusButton from 'src/Components/Shared/StatusButton';
import { sendInvoice } from 'src/Redux/App/Actions/Jobs';
import { useDispatch } from 'react-redux';
import { PaymentStatus } from 'src/Assets/Content/paymentStatus';
import axios from 'axios';
import { Button, notification } from 'antd';
import moment from 'moment';

export const data = [
  {
    Order: 46714,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Meshal',
    'Last Name': 'Muzaffar',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-13 11:54:21',
  },
  {
    Order: 46715,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Francesca',
    'Last Name': 'Miele',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-13 13:52:41',
  },
  {
    Order: 46716,
    Amount: '$475',
    Status: 'Pending',
    'First Name': 'Valri',
    'Last Name': 'Acosta',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-13 16:56:13',
  },
  {
    Order: 46717,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Elizaveta',
    'Last Name': 'Eisenberg',
    'Company Name': 'Filipovits, Attorney at Law',
    'Date Ordered': '2023-12-13 19:41:50',
  },
  {
    Order: 46718,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Jim',
    'Last Name': 'Kahng',
    'Company Name': 'Filipovits, Attorney at Law',
    'Date Ordered': '2023-12-13 19:53:40',
  },
  {
    Order: 46719,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Elizaveta',
    'Last Name': 'Eisenberg',
    'Company Name': 'Miele',
    'Date Ordered': '2023-12-13 11:08:30',
  },
  {
    Order: 46720,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Elizaveta',
    'Last Name': 'Eisenberg',
    'Company Name': 'Miele',
    'Date Ordered': '2023-12-13 11:20:34',
  },
  {
    Order: 46721,
    Amount: '$275',
    Status: 'Processing',
    'First Name': 'Cissy',
    'Last Name': 'Lee',
    'Company Name': 'Herman, Operations Manager & Sr.',
    'Date Ordered': '2023-12-13 13:40:55',
  },
  {
    Order: 46722,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Meshal',
    'Last Name': 'Muzaffar',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-13 15:28:38',
  },
  {
    Order: 46723,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Jumin',
    'Last Name': 'Chen',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-13 15:54:42',
  },
  {
    Order: 46724,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Emily',
    'Last Name': 'Edgar',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-13 16:02:28',
  },
  {
    Order: 46725,
    Amount: '$475',
    Status: 'Processing',
    'First Name': 'Marilyn',
    'Last Name': 'Soto',
    'Company Name': 'Filipovits, Attorney at Law',
    'Date Ordered': '2023-12-13 16:54:48',
  },
  {
    Order: 46726,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Jumin',
    'Last Name': 'Chen',
    'Company Name': 'Filipovits, Attorney at Law',
    'Date Ordered': '2023-12-13 17:52:28',
  },
  {
    Order: 46727,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Yong',
    'Last Name': 'Men',
    'Company Name': 'Miele',
    'Date Ordered': '2023-12-13 18:26:27',
  },
  {
    Order: 46728,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Gina',
    'Last Name': 'Park',
    'Company Name': 'Miele',
    'Date Ordered': '2023-12-12 19:52:54',
  },
  {
    Order: 46729,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Ben',
    'Last Name': 'Chung',
    'Company Name': 'Herman, Operations Manager & Sr.',
    'Date Ordered': '2023-12-13 19:52:54',
  },
  {
    Order: 46730,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Melissa',
    'Last Name': 'Liu',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-14 09:26:19',
  },
  {
    Order: 46731,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Melissa',
    'Last Name': 'Swapp',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-14 10:05:34',
  },
  {
    Order: 46732,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Melissa',
    'Last Name': 'Koutsis',
    'Company Name': 'Luttman, Paralegal',
    'Date Ordered': '2023-12-14 10:36:04',
  },
  {
    Order: 46733,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Todd',
    'Last Name': 'Gonzalez',
    'Company Name': 'Filipovits, Attorney at Law',
    'Date Ordered': '2023-12-14 10:51:12',
  },
  {
    Order: 46734,
    Amount: '$475',
    Status: 'Processing',
    'First Name': 'Todd',
    'Last Name': 'Silver',
    'Company Name': 'Filipovits, Attorney at Law',
    'Date Ordered': '2023-12-15 09:00:12',
  },
  {
    Order: 46735,
    Amount: '$375',
    Status: 'Processing',
    'First Name': 'Francesca',
    'Last Name': 'Goldsmith',
    'Company Name': 'Miele',
    'Date Ordered': '2023-12-15 12:36:52',
  },
  {
    Order: 46736,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Francesca',
    'Last Name': 'Brooks',
    'Company Name': 'Miele',
    'Date Ordered': '2023-12-15 13:58:57',
  },
  {
    Order: 46741,
    Amount: '$275',
    Status: 'Pending',
    'First Name': 'Xiaorong',
    'Last Name': 'Chun',
    'Company Name': 'Rong',
    'Date Ordered': '2023-12-15 15:54:28',
  },
  {
    Order: 46742,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Catherine',
    'Last Name': 'Kim',
    'Company Name': 'Wigthman-Ramirez, Esq.',
    'Date Ordered': '2023-12-15 17:34:04',
  },
  {
    Order: 46741,
    Amount: '$275',
    Status: 'Pending',
    'First Name': 'Xiaorong',
    'Last Name': 'Chun',
    'Company Name': 'Rong',
    'Date Ordered': '2023-12-15 15:54:28',
  },
  {
    Order: 46742,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Catherine',
    'Last Name': 'Kim',
    'Company Name': 'Wigthman-Ramirez, Esq.',
    'Date Ordered': '2023-12-15 17:34:04',
  },
  {
    Order: 46741,
    Amount: '$275',
    Status: 'Pending',
    'First Name': 'Xiaorong',
    'Last Name': 'Chun',
    'Company Name': 'Rong',
    'Date Ordered': '2023-12-15 15:54:28',
  },
  {
    Order: 46742,
    Amount: '$375',
    Status: 'Pending',
    'First Name': 'Catherine',
    'Last Name': 'Kim',
    'Company Name': 'Wigthman-Ramirez, Esq.',
    'Date Ordered': '2023-12-15 17:34:04',
  },
];

export const columns = [
  {
    title: 'Order Number',
    dataIndex: 'jobNumber',
    key: 'jobNumber',
    width: 100,
    render: (text: any, record: any) => {
      let jobNum = '';
      if (record?.jobNumber?.includes('-')) {
        jobNum = record['jobNumber'];
      } else {
        jobNum =
          record?.user?.accountNumber == undefined
            ? ''
            : `${record?.user?.accountNumber}-${record['jobNumber']}`;
      }
      return (
        <div className='center-align'>
          <>{jobNum}</>
        </div>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'amount',
    width: 100,
    render: (text: any, record: any) => {
      const amount = record?.packages?.price
        ? `$${record?.packages?.price}`
        : '';
      return (
        <div className='center-align'>
          <>{amount}</>
        </div>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'status',
    width: 100,
    className: 'center-align',
    render: (text: any, record: any) => {
      const openReceipt = (record: any) => {
        const baseUrl = window.location.origin; // e.g., "http://localhost:3001"
        window.location.href = `${baseUrl}/receipt/${
          record?.receiptId || null
        }`;
      };
      return (
        <div className='center-align'>
          {record?.payments ? (
            <>
              {' '}
              {record?.payments?.length === 0 ? (
                <StatusButton status={PaymentStatus.Pending} />
              ) : record?.payments[0] && record?.payments[0] ? (
                <span onClick={() => openReceipt(record)}>
                  <StatusButton status={PaymentStatus.Paid} />
                </span>
              ) : (
                <StatusButton status={PaymentStatus.Pending} />
              )}{' '}
            </>
          ) : (
            ''
          )}
        </div>
      );
    },
  },
  {
    title: 'First Name',
    dataIndex: 'First Name',
    key: 'first_name',
    width: 150,
    render: (text: any, record: any) => {
      return (
        <div className='center-align'>
          {record?.user ? record?.user?.firstName : ''}
        </div>
      );
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'Last Name',
    key: 'last_name',
    width: 150,
    render: (text: any, record: any) => {
      return (
        <div className='center-align'>
          {record?.user ? record?.user?.lastName : ''}
        </div>
      );
    },
  },
  {
    title: 'Company Name',
    dataIndex: 'Company Name',
    key: 'company_name',
    width: 330,
    render: (text: any, record: any) => {
      return (
        <div className='center-align'>
          {record?.payments ? record?.employerInfo?.companyName : ''}
        </div>
      );
    },
  },
  {
    title: 'Date Ordered',
    dataIndex: 'submittedDate',
    key: 'submittedDate',
    width: 200,
    render: (text: any, record: any) => {
      const date: any = moment(record.submittedDate).format('MM-DD-YYYY'); // Convert backend date string to Moment object

      return <div className='center-align'>{date ? date : null}</div>;
    },
  },
];

const onSendInvoice = async (record: any) => {
  const invoice = [
    {
      accountHolder: record.user.users_email,
      invoice: {
        Line: [
          {
            DetailType: 'SalesItemLineDetail',
            Amount: record?.packages?.price,
            SalesItemLineDetail: {
              ItemRef: {
                name: record.jobTitle,
                value: '1',
              },
            },
          },
        ],
        CustomerRef: {
          value: localStorage.getItem('customerId'),
        },
      },
    },
  ];
  try {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/quickbooks/add-invoice`,
        invoice,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res: any) => {
        notification.success({
          message: 'Invoice Send Successfully!',
        });
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      });
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
  }
};
